export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "",
    domain: "",
    gaId: "",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
